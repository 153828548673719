import {
  IApplicationInfo,
  ICarrierStatementGroup,
  IUnderwritingDetailsInfo,
} from '@coverforce-platform/cf-common-api-model';
import { Carrier, StatementType, UWQuestionCategory } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getUWQuestionV2 } from '../../externalServices/applicationV2';
import { getUWStatements } from '../../externalServices/V2/applicationV2';
import { useApplicationStore } from '../../pages/v2/application/store';
import { get as _get, omit, set as _set } from '../../utils/lodash';
import { scrollAndFocusFormItemErrorV2 } from '../../utils/scrollAndFocusFormItemError';
import { UNDER_WRITING_STORE, UNDERWRITING_QUESTION_CATEGORY, UNDERWRITING_STEP_KEYS } from './constants';
import { IAntdLocationQuestion, IUnderWritingQuestionsStore, IUwFormAnswers } from './interface';
import {
  addUwStatementInAppPayload,
  convertAnswerToApiSupportedAnswer,
  fetchLocationUwQuestions,
  filterDifferentCarrierAnswer,
  getAntdSupportedAnswerFormatForAutoFillUpAnswer,
  getConvertedAnswers,
  getCurrentUwStepCarrier,
  getUwLocationsPayload,
  isLocationUwQuestionFetched,
  processUWQuestion,
  updateAllGeneralUwQuestion,
  updateAllLocationUwQuestion,
  updateUwQuestionWhileAutofill,
  validateUnderWritingQuestion,
} from './utils';

export const useUnderWritingQuestionStore = create<IUnderWritingQuestionsStore>()(
  devtools(
    (set, get) => ({
      uwApplicationLoading: false,
      uwLocationLoading: false,
      underWritingQuestionForm: undefined,
      antdLocationQuestionData: undefined,
      antdGeneralQuestionData: undefined,
      underWritingStatements: {
        data: undefined,
        error: undefined,
        loading: false,
      },
      uwLocationError: undefined,
      uwApplicationError: undefined,
      formValues: undefined,
      currentFieldName: undefined,

      setUnderWritingQuestionForm: (form) => {
        set({ underWritingQuestionForm: form }, false, UNDER_WRITING_STORE.SET_UW_QUESTION_FORM);
      },

      updateUnderWritingQuestionsByKey: (storeKey, value) => {
        set({ [storeKey]: value }, false, UNDER_WRITING_STORE.UPDATE_UW_STORE_BY_KEY);
      },

      fetchApplicationUnderWritingQuestion: async (loading = true) => {
        try {
          const { applicationData } = useApplicationStore.getState();

          set({ uwApplicationLoading: loading }, false, UNDER_WRITING_STORE.LOADING_FETCH_UW_APPLICATION_QUESTION);

          // fetch application level underwriting question | uw category as general
          const questions = await getUWQuestionV2(
            {
              applicationId: applicationData?.applicationId || '',
            },
            { category: UNDERWRITING_QUESTION_CATEGORY.GENERAL as UWQuestionCategory },
          );

          const antdGeneralQuestionData = processUWQuestion({
            questions,
          });

          // updating the childToParentQuestionsMap for each parent question
          const carriers = Object.keys(antdGeneralQuestionData) as Carrier[];
          carriers?.forEach((carrier) => {
            if (antdGeneralQuestionData?.[carrier] && antdGeneralQuestionData[carrier].length > 0) {
              antdGeneralQuestionData[carrier] = updateAllGeneralUwQuestion({
                questions: antdGeneralQuestionData[carrier],
                prefixDataIndex: [UNDERWRITING_STEP_KEYS.APPLICATION, carrier],
                questionValues: applicationData?.underwritingDetails,
              });
            }
          });

          set(
            {
              antdGeneralQuestionData,
              uwApplicationError: undefined,
              uwApplicationLoading: false,
            },
            false,
            UNDER_WRITING_STORE.SET_UW_APPLICATION_QUESTIONS,
          );
        } catch (error: any) {
          set(
            {
              antdGeneralQuestionData: undefined,
              uwApplicationError: error,
              uwApplicationLoading: false,
            },
            false,
            UNDER_WRITING_STORE.SET_ERROR_APPLICATION_UW_QUESTIONS,
          );
        }
      },

      fetchLocationUnderWritingQuestion: async (loading = true) => {
        try {
          let antdLocationQuestionData: IAntdLocationQuestion[] = [];
          const { applicationData } = useApplicationStore.getState();

          set({ uwLocationLoading: loading }, false, UNDER_WRITING_STORE.LOADING_FETCH_UW_LOCATION_QUESTION);

          // fetch all location based questions
          if (isLocationUwQuestionFetched()) {
            antdLocationQuestionData = await fetchLocationUwQuestions();
          }

          // updating the childToParentQuestionsMap for each parent question
          antdLocationQuestionData?.forEach((location, index) => {
            const carriers = Object.keys(location.questions) as Carrier[];
            carriers?.forEach((carrier) => {
              const questionData = location.questions?.[carrier];
              if (questionData && questionData.length > 0) {
                antdLocationQuestionData[index]['questions'][carrier] = updateAllLocationUwQuestion({
                  questions: questionData,
                  locationId: location.location.locationId || '',
                  prefixDataIndex: [UNDERWRITING_STEP_KEYS.LOCATIONS, location.location.locationId || '', carrier],
                  questionValues: applicationData?.locationDetails,
                });
              }
            });
          });

          set(
            {
              antdLocationQuestionData,
              uwLocationError: undefined,
              uwLocationLoading: false,
            },
            false,
            UNDER_WRITING_STORE.SET_UW_LOCATION_QUESTIONS,
          );
        } catch (error: any) {
          set(
            {
              antdLocationQuestionData: undefined,
              uwLocationError: error,
              uwLocationLoading: false,
            },
            false,
            UNDER_WRITING_STORE.SET_ERROR_LOCATION_UW_QUESTIONS,
          );
        }
      },

      fetchUnderWritingStatements: async (params) => {
        const { loading, retryCount } = { loading: true, retryCount: 0, ...(params || {}) };

        const { applicationData } = useApplicationStore.getState();
        try {
          const underWritingStatement: { [key: string]: ICarrierStatementGroup } = {};
          set(
            {
              underWritingStatements: {
                ...get().underWritingStatements,
                loading,
              },
            },
            false,
            UNDER_WRITING_STORE.LOADING_FETCH_UW_STATEMENT,
          );

          const uwStatements = await getUWStatements(
            { applicationId: applicationData?.applicationId || '' },
            { type: StatementType.UNDERWRITING },
          );

          uwStatements?.disclosures?.map((el) => {
            underWritingStatement[el?.carrier] = el;
          });

          set(
            {
              underWritingStatements: {
                data: underWritingStatement,
                error: undefined,
                loading: false,
              },
            },
            false,
            UNDER_WRITING_STORE.FETCH_UNDERWRITING_STATEMENT,
          );
        } catch (error) {
          if (retryCount === 0) {
            get().fetchUnderWritingStatements({ retryCount: retryCount + 1 });
          } else {
            set(
              {
                underWritingStatements: {
                  data: undefined,
                  error,
                  loading: false,
                },
              },
              false,
              UNDER_WRITING_STORE.UW_STATEMENT_ERROR,
            );
          }
        }
      },

      validateUnderWritingStep: async () => {
        const { underWritingQuestionForm } = get();

        try {
          const isUwQuestionValid = await validateUnderWritingQuestion();
          scrollAndFocusFormItemErrorV2(underWritingQuestionForm);
          return isUwQuestionValid;
        } catch (error) {
          scrollAndFocusFormItemErrorV2(underWritingQuestionForm);
          return false;
        }
      },

      getUnderWritingStepPayload: () => {
        const { underWritingQuestionForm } = get();
        const { applicationData } = useApplicationStore.getState();

        if (applicationData) {
          const formAnswers: IUwFormAnswers = {
            ...underWritingQuestionForm?.getFieldsValue(),
          };

          const currentCarrier = getCurrentUwStepCarrier();

          const uwAnswers = _get(formAnswers, [UNDERWRITING_STEP_KEYS.APPLICATION, currentCarrier], {});
          const parsedUWAnswers = omit(uwAnswers, UNDERWRITING_STEP_KEYS.UW_DECLARATION_QUESTION);

          _set(formAnswers, [UNDERWRITING_STEP_KEYS.APPLICATION, currentCarrier], parsedUWAnswers);

          const underWritingAnswers: {
            application: { [k: string]: string };
            locations: { [k: string]: { [k: string]: string } };
          } = getConvertedAnswers(formAnswers, currentCarrier);

          const uwQuestionAndAnswer: {
            application: IUnderwritingDetailsInfo[];
            locations: { [key: string]: IUnderwritingDetailsInfo[] };
          } = convertAnswerToApiSupportedAnswer(underWritingAnswers);

          const { applicationPayload, locationBasedAnswer } = filterDifferentCarrierAnswer({
            uwQuestionAndAnswer,
            currentCarrier,
          });
          return {
            applicationPayload: addUwStatementInAppPayload(applicationPayload as IApplicationInfo),
            locationsUwPayload: getUwLocationsPayload(locationBasedAnswer),
          };
        }
        return { applicationPayload: undefined, locationsUwPayload: undefined };
      },

      autoFillUnderWritingData: () => {
        const { underWritingQuestionForm } = get();
        const currentCarrier = getCurrentUwStepCarrier();

        const { application, locations } = getAntdSupportedAnswerFormatForAutoFillUpAnswer({
          selectedCarrier: currentCarrier,
        });
        updateUwQuestionWhileAutofill({
          carrier: currentCarrier,
        });
        underWritingQuestionForm?.setFieldsValue({
          application,
          locations,
        });
      },

      clearUnderWritingQuestionStore: () =>
        set(
          () => ({
            antdLocationQuestionData: undefined,
            antdGeneralQuestionData: undefined,
            uwApplicationError: undefined,
            uwLocationError: undefined,
            uwApplicationLoading: false,
            uwLocationLoading: false,
            underWritingQuestionForm: undefined,
            underWritingStatements: {
              data: undefined,
              error: undefined,
              loading: false,
            },
            formValues: undefined,
            currentFieldName: undefined,
          }),
          false,
          UNDER_WRITING_STORE.CLEAR_UNDERWRITING,
        ),
    }),
    { anonymousActionType: UNDER_WRITING_STORE.ANONYMOUS_ACTION, name: UNDER_WRITING_STORE.NAME },
  ),
);
